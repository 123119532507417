import React, { useState } from 'react';

//plugins
import Modal from 'react-modal';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { Button } from './layout/StyledComponents';

interface Props {
    modalActive?: boolean;
    setModalActive: () => boolean;
}

const StyledModal = styled(Modal)`
    &:focus {
        outline: none !important;
    }
    z-index: 80000;
    color: ${colors.navy};
    button {
        cursor: pointer;
    }
    h3,
    p,
    ul {
        color: ${colors.navy};
    }
    .form-item {
        position: relative;
        input {
            background: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(16px);
            color: ${colors.navy};
            border: none;
            min-width: 300px;
            margin: 0.5em 0em;
            padding: 1.25em;

            text-indent: 50px;
            z-index: 1;
        }
    }
    .form-container {
        display: flex;
        position: relative;
        justify-content: center;

        button {
            position: absolute;
            right: 1em;
            justify-self: end;
            margin-top: 1em;
        }
    }

    .icon {
        z-index: 2;
        top: 1em;
        left: 0.7em;

        position: absolute;
    }
    .open {
        z-index: 80000;
        &:focus {
            outline: none;
        }
    }
    h3 {
        padding-left: 0.25em;
        font-size: 26px;
        margin-bottom: 0.25em;
        line-height: 35px;
    }
    ul {
        li {
            margin: 0.5em 0em;
        }
        padding-left: 1.75em;
    }
    p {
        padding-left: 0.25em;
        font-weight: 800;
    }
    .overlap {
        overflow: hidden;
        position: relative;
        .exit {
            border: none;
            background: none;
            z-index: 1000000;
            cursor: pointer;
            position: absolute;
            right: 1em;
            top: 1em;
        }
        .one {
            bottom: -8em;
            z-index: -1;
            left: -0em;
        }
        .two {
            z-index: -1;
            left: 0em;
            top: -5em;
        }
        .three {
            right: -3em;
            top: -14em;
        }
        .four {
            bottom: 0em;
            right: 0em;
        }
        .gold-line {
            position: absolute;
        }
        position: relative;
        background: radial-gradient(
            100% 254.87% at 0% 100%,
            #85b6e2 0%,
            #cbd8e0 54.64%,
            #e3e3d9 93.47%
        );
        /* Card-drop-shadow */
        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.1);
        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.1);
        border-radius: 64px 0px;
        padding: 3em 1em 6.5em;
        margin: 10vh 0.75em 0em;
        z-index: 80000;
    }
    @media ${device.tablet} {
        .overlap {
            .exit {
                border: none;
                background: none;
                z-index: 1000000;
                cursor: pointer;
                position: absolute;
                right: 1em;
                top: 1.25em;
            }
            .one {
                top: -3.2em;
                z-index: -1;
                left: -0em;
            }
            .two {
                z-index: -1;
                left: 0em;
                top: 0em;
            }
            .three {
                z-index: -1;
                right: 3em;
                top: 0em;
            }
            .four {
                bottom: 0em;
                right: 0em;
            }
            margin: 15vh auto 0em;
            max-width: 540px;
        }
    }
    @media ${device.laptopL} {
        .overlap {
            padding: 3.25em 4em 2em 1.25em;
            display: flex;
            height: 400px;
            justify-content: space-between;
            margin: 15vh auto 0em;
            max-width: 720px;
            .exit {
                top: -2.75em;
            }
            button {
                margin-top: 4em;
            }
            .text-container {
                padding: 0em 2em 0em 2em;
                h3 {
                    margin-bottom: 1.25em;
                }
            }
        }
        .form-item {
            position: relative;
            .icon {
                top: 1.2em;
                left: 1em;
            }
            input {
                padding: 1.25em;
            }
        }
    }
`;

export const VipModal: React.FC<Props> = ({ modalActive, setModalActive }) => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');
        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledModal
            className="open"
            isOpen={modalActive}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setModalActive(false)}
            style={{
                overlay: {
                    zIndex: '800000',
                    backgroundColor: 'rgba(0,0,0,0.9)',
                },
            }}
        >
            <div className="overlap">
                <button onClick={() => setModalActive(false)} className="exit">
                    <StaticImage
                        src="../images/svgs/exit-navy.svg"
                        placeholder="none"
                        quality={100}
                        alt="exit button"
                    />
                </button>
                <StaticImage
                    className="gold-line a one"
                    src="../images/gold-line.png"
                    placeholder="none"
                    quality={100}
                    alt="gold-line"
                />
                <StaticImage
                    quality={100}
                    className="gold-line two"
                    src="../images/gold-line-two.png"
                    placeholder="none"
                    alt="gold-line"
                />
                <StaticImage
                    quality={100}
                    className="gold-line three"
                    src="../images/gold-line-three.png"
                    placeholder="none"
                    alt="gold-line"
                />
                <StaticImage
                    quality={100}
                    className="gold-line four"
                    src="../images/gold-line-four.png"
                    placeholder="none"
                    alt="icon"
                />
                <div className="text-container">
                    <h3>Join our SmileCraft VIP list today!</h3>
                    <p>Benefits include:</p>
                    <ul>
                        <li>Priority Booking</li>
                        <li>Free SmileCraft Gear</li>
                        <li>Special offers</li>
                        <li>Discounts</li>
                    </ul>
                </div>

                <div className="form-container">
                    <form onSubmit={onSubmit}>
                        <div className="form-item">
                            <StaticImage
                                className="icon"
                                src="../images/svgs/avatar-navy.svg"
                                placeholder="none"
                                alt="avatar icon"
                            />
                            <input
                                required
                                className="full"
                                placeholder="Enter your Full Name"
                                name="vipName"
                                type="text"
                                id="vipName"
                            />
                        </div>
                        <div className="form-item">
                            <StaticImage
                                className="icon"
                                src="../images/svgs/mail-navy.svg"
                                placeholder="none"
                                alt="mail icon"
                            />
                            <input
                                required
                                className="half"
                                placeholder="Enter your e-mail address"
                                name="vipEmail"
                                type="text"
                                id="vipEmail"
                            />
                        </div>
                        <div className="form-item">
                            <StaticImage
                                className=" mobile icon"
                                src="../images/svgs/mobile-navy.svg"
                                placeholder="none"
                                alt="mobile icon"
                            />
                            <input
                                required
                                className="half"
                                placeholder="Enter your Phone Number"
                                name="vipPhone"
                                type="tel"
                                id="vipPhone"
                            />
                        </div>
                        {submitState === 'submitted' ? (
                            <>
                                <Button
                                    disabled
                                    color="blue"
                                    style={{ cursor: 'auto', color: `${colors.white}` }}
                                >
                                    Thank you!
                                </Button>
                            </>
                        ) : (
                            <Button color="blue" type="submit">
                                {submitState === 'submitting' ? 'Joining...' : 'JOIN THE LIST'}
                            </Button>
                        )}
                    </form>
                </div>
            </div>
        </StyledModal>
    );
};
