import { Link } from 'gatsby';
import { useState, useEffect } from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { HomeHero } from '../components/HomeHero';
import { BottomHero } from '../components/BottomHero';
import { Insurance } from '../components/Insurance';
import { HomeServices } from '../components/HomeServices';
import { HomeDoctor } from '../components/HomeDoctor';
import { HomeTestimonials } from '../components/HomeTestimonials';
import { HomeVideo } from '../components/HomeVideo';
import { Seo } from '../components/Seo';
import { VipModal } from '../components/Modal';

const Home: Page = () => {
    const [modalActive, setModalActive] = useState(true);

    return (
        <>
            <DefaultLayout home>
                <Seo
                    title="Dentist Lutz, FL | Smilecraft Dental Studio"
                    description="Smilecraft Dental Studio specializes in family & cosmetic dentistry, including fillings, sealants, root canals, veneers, dental implants, and more. Call us!"
                />
                <VipModal modalActive={modalActive} setModalActive={setModalActive} />
                <HomeHero />
                <BottomHero />
                <Insurance />
                <HomeServices />
                <HomeDoctor />
                <HomeTestimonials />
                <HomeVideo />
            </DefaultLayout>
        </>
    );
};

export default Home;
