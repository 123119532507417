import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';

const StyledHomeHero = styled.div`
    padding-top: 8em;
    z-index: 0;
    .title {
        margin-left: 1.5em;
        width: 268px;
        height: auto;
    }
    .image-container {
        .blue-line,
        .blue-line-two {
            width: 2px;
            height: 100vh;
            background: rgb(24, 131, 231);
            background: linear-gradient(
                0deg,
                rgba(239, 239, 239, 1) 0%,
                rgba(239, 239, 239, 1) 0%,
                rgba(24, 131, 231, 1) 100%
            );
            display: none;
        }
        position: relative;
        .blue-panel,
        .gold-panel,
        .blue-panel {
            position: absolute;
            width: 338px;
            height: 388px;
            bottom: 9.1em;
            z-index: 0;
        }
        .gold-panel-desktop {
            display: none;
        }
        .gold-panel {
            position: absolute;
            z-index: 0;
            left: 0em;
            width: 116px;
            height: 116px;
            top: 14em;
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
        margin: 0em 0em 1.5em;
        button {
            margin: 0em 0.35em;
        }
    }

    .location {
        margin: 2.25em 0em 1.75em 1.5em;
        p {
            margin-left: 1em;
            width: 75%;
            text-decoration: underline;
        }
        align-items: center;
        display: flex;
    }
    .panel-text {
        display: none;
    }
    .line-one,
    .line-two {
        display: none;
    }
    @media ${device.tablet} {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 4em;
        .line-one {
            z-index: 1;
            opacity: 0.4;
        }
        .line-two {
            z-index: 1;
            opacity: 0.5;
        }
        .line-one,
        .line-two {
            display: block;
            top: 0em;
            left: 0em;
            z-index: -1;
            position: absolute;
        }
        .image-container {
            .blue-line {
                display: none;
                position: absolute;
                left: 0em;
                height: 180vh;
                top: 00em;
            }
            .blue-line-two {
                display: none;
                position: absolute;
                right: 15em;
                height: 180vh;
                top: 0.5em;
            }
            .gold-panel-desktop {
                display: block;
                position: absolute;

                top: 10em;
                right: 17em;
                width: 140px;
                margin: 0 auto;
                z-index: 1;
            }
        }

        .blue-panel,
        .gold-panel {
            display: none;
        }
        .title {
            margin-left: 1.5em;
            width: 300px;

            height: auto;
        }
        .buttons {
            display: flex;
            justify-content: start;
            margin: 0em 0em 1em 1em;
            button {
                margin: 0em 0.35em;
            }
        }
        .text-container {
            padding: 0em 1em;
        }
    }
    @media ${device.laptop} {
        padding-top: 1em;
        justify-content: space-between;
        .image-container {
            .blue-line,
            .blue-line-two {
                display: none;
            }
            position: relative;
            .gold-panel-desktop {
                width: 220px;
                top: 20em;
                right: 30em;
                margin: 0 auto;
                z-index: 1;
            }
            .panel-text {
                p {
                    right: 37em;
                    bottom: 17.75em;
                }
                span {
                    bottom: 18em;
                    color: red;
                    left: -5em;
                }
            }
        }

        .title {
            margin-top: 8em;
            width: 400px;
        }
        .buttons {
            button {
                margin-right: 2em;
            }
        }

        .text-container {
            padding: 0em 1em;
            display: flex;
            margin: 0 0em 28vh 3em;
            flex-direction: column;
            align-items: start;
        }
    }

    @media ${device.laptopL} {
        .location {
            margin: 2.25em 0em 1.75em 1.75em;
            p {
                width: 70%;
                margin-left: 2em;
            }
        }
        .image-container {
            .gold-panel-desktop {
                width: 270px;
                top: 24em;
                right: 35em;
                margin: 0 auto;
                z-index: 1;
            }
        }
        .title {
            width: 500px;
        }
        .buttons {
            margin-top: 1em;
        }
        padding-top: 1.5em;
    }
    @media ${device.desktop} {
        .title {
            margin-top: 10em;
        }
        .image-container {
            .blue-line,
            .blue-line-two {
                height: 150vh;
            }
        }
        .text-container {
            padding: 0em 1em;
            display: flex;
            margin: 0 0em 10em 10em;
        }
        .image-container {
            .gold-panel-desktop {
                right: 42em;
                top: 24em;
                width: 290px;
            }
        }
    }
    @media ${device.desktopL} {
        .image-container {
            .gold-panel-desktop {
                right: 53em;
            }
            .location {
                margin: 2.25em 0em 1.75em 1.5em;
                p {
                    margin-left: 1em;
                    width: 75%;

                    text-decoration: underline;
                }
            }
            .text-container {
                width: 700px;
            }
        }
    }
`;

const heroImageStyling = css`
    z-index: 1;
    height: 528px;
    margin: 0 auto;
    @media ${device.tablet} {
        z-index: 1;
        width: 350px;
        height: 528px;
    }
    @media ${device.laptop} {
        height: 100vh;
        width: 50vw;
    }
    @media ${device.laptopL} {
        height: 780px;
        width: 50vw;
    }
    @media ${device.desktop} {
        height: 880px;
        width: 50vw;
    }
    @media ${device.desktopL} {
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <StyledHomeHero>
            <div className="text-container">
                <StaticImage
                    className="line-one"
                    quality={100}
                    src="../images/svgs/line-one.svg"
                    alt="golden line leaning left"
                    placeholder="none"
                />
                <StaticImage
                    className="line-two"
                    quality={100}
                    src="../images/svgs/line-two.svg"
                    alt="golden line leaning right"
                    placeholder="none"
                />
                <StaticImage
                    quality={100}
                    className="title"
                    src="../images/title.png"
                    alt="Smilecraft Dental Studios"
                    placeholder="none"
                />
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/1942+Highland+Oaks+Blvd,+Lutz,+FL+33559/data=!4m2!3m1!1s0x88c2ba29bb8ba8f5:0xa5cb651cf1a0464d?sa=X&ved=2ahUKEwiIkOORj5H2AhUVlYkEHahrCugQ8gF6BAgSEAE"
                >
                    <div className="location">
                        <StaticImage
                            quality={100}
                            src="../images/svgs/map-black.svg"
                            alt="map icon"
                            placeholder="none"
                        />
                        <p>1942 Highland Oaks Blvd Suite B, Lutz, FL 33559</p>
                    </div>
                </a>
                <div className="buttons">
                    <a
                        target="_blank"
                        style={{ color: `${colors.white}` }}
                        rel="noreferrer"
                        href="https://www.flexbook.me/smilecraft/1"
                    >
                        <Button color="blue">Schedule a Visit</Button>
                    </a>
                    <a rel="noopener noreferrer" href="tel:8132354143">
                        <Button style={{ background: '#EFEFEF' }} color="navy">
                            (813) 235-4143
                        </Button>
                    </a>
                </div>
            </div>
            <div className="image-container">
                <div className="blue-line"></div>
                <div className="blue-line-two"></div>
                <StaticImage
                    quality={100}
                    className={heroImageStyling}
                    src="../images/homepage.jpg"
                    placeholder="none"
                    alt="girl waving"
                />
                <StaticImage
                    quality={100}
                    className="gold-panel-desktop"
                    src="../images/gold-panel-desktop.png"
                    placeholder="none"
                    alt="gold panel shape"
                />
                <StaticImage
                    quality={100}
                    className="gold-panel"
                    src="../images/svgs/gold-panel.svg"
                    placeholder="none"
                    alt="gold panel shape"
                />
                <StaticImage
                    quality={100}
                    className="blue-panel"
                    src="../images/svgs/blue-panel.svg"
                    placeholder="none"
                    alt="blue panel shape"
                />
            </div>
        </StyledHomeHero>
    );
};
