import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';
import ok from '../images/svgs/bg-lines.svg';

const StyledHomeServices = styled.div`
    background: url(${ok});
    padding: 1em 0em;
    .column {
        position: relative;
        min-width: 300px;
        min-height: 920px;
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0em 0.75em;
        &-text {
            padding: 0em 0.5em;
            max-width: 300px;
        }
        button {
            position: absolute;
            bottom: 1em;
        }
    }
    p {
        padding: 1em 0em 2em;
        color: ${colors.white};
    }
    h3 {
        max-width: 260px;
        margin-top: 0.5em;
        color: ${colors.white};
    }
    .title {
        padding: 0.25em;
    }
    .scroll-container {
        overflow-x: scroll;
    }
    .scroll {
        display: flex;
        width: 200vw;
    }
    @media ${device.laptop} {
        padding: 1em 0em 6em;
        .scroll-container {
            overflow-x: hidden;
            justify-content: center;
        }
        .title {
            margin: 1.5em auto 1em;
        }
        .scroll {
            margin: 0 auto;
            max-width: 1300px;
            justify-content: space-between;
        }
    }
`;

export const HomeServices: React.FC = () => {
    return (
        <StyledHomeServices>
            <h3 className="title">Our Services</h3>
            <div className="scroll-container">
                <div className="scroll">
                    <div className="column">
                        <StaticImage
                            quality={100}
                            src="../images/family-pic.png"
                            alt="grouped family"
                            placeholder="none"
                        />
                        <div className="column-text">
                            <h3>Family Care Dentistry</h3>
                            <p>
                                We offer dental care for the whole family at SmileCraft. We provide
                                kind, individualized care for every patient, because no two smiles
                                are the same. No matter what age or stage of dental health, everyone
                                who walks through our doors will be treated with kindness and
                                respect.
                            </p>
                            <Link to="/services/family-care-dentistry/">
                                <Button style={{ color: `${colors.gold}` }} color="gold">
                                    find out more
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <div className="column">
                        <StaticImage
                            quality={100}
                            src="../images/botox.png"
                            alt="girl getting injections"
                            placeholder="none"
                        />
                        <div className="column-text">
                            <h3>Restorative Dentistry</h3>
                            <p>
                                Restorative dental treatments are a group of procedures that repair
                                and enhance oral health. From repairing cavities to replacing teeth
                                with dental bridges, restorative treatment can help your smile
                                become healthy once again. If it is a dental issue changes are we
                                can help.
                            </p>
                            <Link to="/services/restorative-dentistry/">
                                <Button style={{ color: `${colors.gold}` }} color="gold">
                                    find out more
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <div className="column">
                        <StaticImage
                            quality={100}
                            src="../images/cosmetic.png"
                            alt="two girls smiling"
                            placeholder="none"
                        />
                        <div className="column-text">
                            <h3>Cosmetic Dentistry</h3>
                            <p>
                                We believe everyone deserves their dream smile and with our
                                expertise and careful attention to detail, we know that it is
                                possible. From dental bonding and teeth whitening to full cosmetic
                                makeovers and everything in between, we've got you covered.
                            </p>
                            <Link to="/services/cosmetic-dentistry/">
                                <Button style={{ color: `${colors.gold}` }} color="gold">
                                    find out more
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <div className="column">
                        <StaticImage
                            quality={100}
                            src="../images/implants.png"
                            alt="dental device"
                            placeholder="none"
                        />
                        <div className="column-text">
                            <h3>Implant Dentistry</h3>
                            <p>
                                A missing tooth can take its toll on your confidence as well as your
                                quality of life. That's why we offer dental implants as permanent
                                solutions that look and feel completely natural. From a single
                                implant to full arch restorations, our implants provide
                                life-changing results.
                            </p>
                            <Link to="/services/implant-dentistry/">
                                <Button style={{ color: `${colors.gold}` }} color="gold">
                                    find out more
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </StyledHomeServices>
    );
};
