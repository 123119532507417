import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';

const StyledInsurance = styled.div`
    background: ${colors.white};
    padding: 2em 1em;
    .scroll {
        overflow-x: scroll;
        padding: 0em;
    }
    .images {
        margin: 2em 0em 2em;
        width: 200vw;
        justify-content: space-between;
        display: flex;
    }
    @media ${device.tablet} {
        .images {
            width: 95vw;
        }
    }
    @media ${device.laptop} {
        align-items: center;
        display: flex;
        padding: 2em 3em 1.5em;
        .scroll {
            overflow-x: hidden;
        }
        h3 {
            max-width: 380px;
        }
        .images {
            margin: 2em 0em 2em;
            width: 800px;
            justify-content: space-around;
            display: flex;
        }
    }
    @media ${device.laptopL} {
        justify-content: center;
        .images {
            margin: 2em 0em 2em;
            width: 800px;
            justify-content: space-around;
            display: flex;
        }
        h3 {
            max-width: 420px;
        }
    }
`;

export const Insurance: React.FC = () => {
    return (
        <StyledInsurance>
            <h3>Accepting all Major Insurances</h3>
            <div className="scroll">
                <div className="images">
                    <StaticImage
                        src="../images/svgs/blue-cross.svg"
                        placeholder="none"
                        alt="blue cross insurance logo"
                    />
                    <StaticImage
                        src="../images/svgs/health-partners.svg"
                        placeholder="none"
                        alt="health partners logo"
                    />
                    <StaticImage
                        src="../images/svgs/cigna.svg"
                        placeholder="none"
                        alt="cigna insurance logo"
                    />
                </div>
            </div>
        </StyledInsurance>
    );
};
