import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { css } from '@emotion/css';
import { SlantedPicture } from './SlantedPicture';

const StyledBottomHero = styled.div`
    .blue-line {
        display: none;
    }
    .credentials {
        display: flex;
        justify-content: center;
    }
    .google {
        display: flex;
        align-items: center;
        width: 65px;
        margin-bottom: -1em;
        justify-content: space-between;
        div {
            position: relative;
            top: -0.5em;

            right: 1em;
        }
    }
    .text-container {
        button {
            margin-bottom: 4em;
        }
    }
    .reviews,
    .dentist-of-year {
        display: flex;
        max-width: 134px;
        margin: 2em 2em 0em;
        text-align: center;
        flex-direction: column;
        align-items: center;
        span {
            margin-bottom: -0.1em;
        }
        p {
            margin-top: 0.5em;
            font-size: 26px;
        }
    }
    .dentist-of-year {
        p {
            margin-top: 0em;
            line-height: 32.8px;
        }
    }
    span {
        font-size: 44px;
        font-weight: 800;
        letter-spacing: -1px;
        color: ${colors.blue};
    }
    h2 {
        margin: 1em 1em 0em 0em;
    }
    p {
        margin: 1em 0em;
    }

    @media ${device.tablet} {
        h2 {
            margin: 0em 1em 0em 0em;
            padding-top: 1em;
        }
        .office-image {
            width: 100vw;
        }
        .reviews,
        .dentist-of-year {
            margin: 1em 1em 0em;
        }
        .dentist-of-year {
            margin-top: 40px;
        }
        .reviews {
            position: relative;
            .blue-line {
                width: 2px;
                height: 40vh;
                background: rgb(24, 131, 231);
                background: linear-gradient(
                    0deg,
                    rgba(239, 239, 239, 1) 0%,
                    rgba(239, 239, 239, 1) 0%,
                    rgba(24, 131, 231, 1) 100%
                );
                top: 00em;
                position: absolute;
                right: -6em;
            }
        }
        position: relative;
        margin-top: 0em;
        .credentials {
            top: -4em;
            width: 340px;
            margin-left: 3em;
            justify-content: space-around;
            position: absolute;
        }
    }
    @media ${device.laptop} {
        top: 0em;
        position: relative;
        .office-image {
            min-width: 50vw;
            height: 890px;
        }
        .text-container {
            height: 800px;
            width: 50vw;
            padding: 0em 2em;
        }
        .credentials {
            .blue-line {
                display: block;
                right: -6em;
                top: -0.24em;
            }
            margin-left: 2em;
            top: -12em;
            width: 540px;
            justify-content: space-around;
            position: absolute;
            .google {
                p {
                    line-height: 10.8px;
                    position: relative;
                    top: 0.4em;
                }
            }

            .dentist-of-year {
                max-width: 210px;

                p {
                    width: 100%;
                }
            }
        }
    }
    @media ${device.laptopL} {
        top: 0em;
        .office-image {
            height: 700px;
            margin: 0em;
        }
        .text-container {
            height: 680px;
            width: 50vw;
            padding: 0em 2em;
        }
        .credentials {
            width: 640px;
            top: -12.5em;
            margin-left: 4em;
            .blue-line {
                display: block;
                right: -7em;
                top: 0.4em;
            }
        }
    }
    @media ${device.desktop} {
        .credentials {
            margin-left: 8em;
            top: -12em;
            width: 580px;
            justify-content: space-around;
            position: absolute;
            .blue-line {
                z-index: -1;
                right: -10em;
                top: -9.5em;
                display: none;
            }
        }
    }
    @media ${device.desktop} {
        .office-image {
            height: 670px;
            margin: 0em 0em 0em 0em;
        }
        .text-container {
            height: 620px;
            width: 50vw;
            padding: 0em 2em;
        }
    }
    @media ${device.desktopL} {
        .credentials {
            .blue-line {
                display: none;
                right: -10em;
                top: -1.9em;
            }
        }
        top: 0em;
    }
`;

export const BottomHero: React.FC = () => {
    return (
        <StyledBottomHero>
            <div className="credentials">
                <div className="reviews">
                    <span>250+</span>
                    <div className="google">
                        <p style={{ position: 'relative', top: '0em' }}>5</p>
                        <StaticImage
                            quality={100}
                            src="../images/svgs/star.svg"
                            alt="star icon"
                            placeholder="none"
                        />
                    </div>
                    <p style={{ margin: '0em' }}> Reviews</p>
                    <div className="blue-line"></div>
                </div>

                <div className="dentist-of-year">
                    <p>Tampa’s Dentist of the Year</p>
                </div>
            </div>

            <SlantedPicture>
                <div className="flex-container">
                    <div className="text-container">
                        <h2>Join Our SmileCraft Family In Lutz, FL</h2>
                        <p>
                            Your smile is one of the first things people notice about you, and we
                            believe that is the key to a confident, happier you. Our goal is to
                            create a judgment-free, pressure-free experience where you feel
                            comfortable asking questions and feel heard. Our focus is you. As a
                            member of our family, we ensure that you fully understand your treatment
                            so we can make the best decisions for your smile together. With our
                            industry- leading technology, we've created a place where all your
                            dental needs will be taken care of under one roof. SmileCraft Dental
                            Studio was created for you and we cant wait to meet you!
                        </p>
                        <Link to="/contact-us/">
                            <Button color="blue">Contact Us</Button>
                        </Link>
                    </div>
                    <div className="image-container">
                        <StaticImage
                            className="office-image"
                            src="../images/studio.jpg"
                            placeholder="none"
                            alt="front shot of the studio"
                        />
                    </div>
                </div>
            </SlantedPicture>
        </StyledBottomHero>
    );
};
